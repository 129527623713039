import React from 'react'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import styles from './about.module.scss'

const AboutPage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const about = data.allContentfulAbout.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={about.title}
        description={about.description}
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
        <section className={styles.hero}>
          <h1 className={styles.hero_heading}>
            <FormattedMessage id="about" />
          </h1>
          <figure className={styles.hero_img}>
            <Img fluid={data.hero.childImageSharp.fluid} />
          </figure>
        </section>
        <div className={styles.wrapper}>
          <section
            className={styles.mission}
            dangerouslySetInnerHTML={{
              __html: about.content.childMarkdownRemark.html,
            }}
          ></section>
          <section className={styles.about}>
            <h2>{about.information}</h2>
            <dl>
              <dt>{about.companyNameLabel}</dt>
              <dd>{about.companyName}</dd>
              <dt>{about.addressLabel}</dt>
              <dd>{about.address}</dd>
              <dt>{about.establishedLabel}</dt>
              <dd>{about.established}</dd>
              <dt>{about.telLabel}</dt>
              <dd>{about.tel}</dd>
              <dt>{about.descriptionOfBusinessLabel}</dt>
              <dd>{about.descriptionOfBusiness}</dd>
            </dl>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulAbout(filter: { node_locale: { eq: $localeLanguage } }) {
      edges {
        node {
          content {
            childMarkdownRemark {
              html
            }
          }
          title
          description
          information
          companyNameLabel
          companyName
          addressLabel
          address
          establishedLabel
          established
          telLabel
          tel
          descriptionOfBusinessLabel
          descriptionOfBusiness
        }
      }
    }
    hero: file(relativePath: { eq: "about.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
